import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { PageFeaturedBuildsCategories } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import FeaturedBuilds from '@webPages/FeaturedBuilds/FeaturedBuilds';
import decodeFeatured from '@decoders/Featured';

const FeaturedBuildsPageCategory: React.FC<
  PageProps<PageFeaturedBuildsCategories>
> = ({ data }) => {
  const title = data?.featuredBuilds?.frontmatter?.title || '';
  let seoTitle = data?.featuredBuilds?.frontmatter?.seoTitle || title || '';

  if (!seoTitle.includes('|')) {
    const seoTitlePostFix = ' | Custom Modular Homes | Ecoliv';
    seoTitle = `${seoTitle}${seoTitlePostFix}`;
  }

  const seoDescription =
    data?.featuredBuilds?.frontmatter?.seoDescription ||
    data?.featuredBuilds?.excerpt ||
    undefined;
  const body = data?.featuredBuilds?.html || '';

  const image =
    data?.featured?.edges[0]?.node?.frontmatter?.thumb?.publicURL || undefined;

  const featured = decodeFeatured(data?.featured?.edges || [])
    .filter(f => !f.hideOnIndex)
    .sort((a, b) => {
      if (a.date && b.date) {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      } else if (a.date) {
        return -1;
      } else if (b.date) {
        return 1;
      }
      return 0;
    });

  const props = {
    title,
    body,
    featured,
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <FeaturedBuilds {...props} />
    </Base>
  );
};

export default FeaturedBuildsPageCategory;

export const query = graphql`
  query PageFeaturedBuildsCategories {
    featuredBuilds: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "featured-builds" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
      }
    }

    featured: allMarkdownRemark(
      filter: { fields: { collection: { eq: "featured" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            hideOnIndex
            date
            thumb {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  width: 924
                  height: 520
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, JPG]
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`;
