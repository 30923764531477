import * as Route from '@routes';

export const tagCloudOptionsWithoutAll = [
  {
    tag: 'single-storey',
    title: 'Single-storey',
    seoTitle: 'Featured Single Storey Custom Projects | Ecoliv',
    href: Route.featuredBuildsSingleStorey(),
  },
  {
    tag: 'double-storey',
    title: 'Double-storey',
    seoTitle: 'Featured Double Storey Custom Projects | Ecoliv',
    href: Route.featuredBuildsDoubleStorey(),
  },
  {
    tag: 'on-site',
    title: 'On-site',
    seoTitle: 'Featured On Site Custom Projects | Ecoliv',
    href: Route.featuredBuildsOnSite(),
  },
  {
    tag: 'off-grid',
    title: 'Off-grid',
    seoTitle: 'Featured Off Grid Custom Projects | Ecoliv',
    href: Route.featuredBuildsOffGrid(),
  },
  {
    tag: 'extensions',
    title: 'Extensions',
    seoTitle: 'Featured Extensions Custom Projects | Ecoliv',
    href: Route.featuredBuildsExtensions(),
  },
  {
    tag: 'secondary-dwellings',
    title: 'Secondary Dwellings',
    seoTitle: 'Featured On Secondary Dwellings Projects | Ecoliv',
    href: Route.featuredBuildsSecondaryDwellings(),
  },
];

export const tagCloudOptions = [
  {
    tag: '',
    title: 'All',
    seoTitle: 'Featured Eco-Friendly Homes & Custom Projects | Ecoliv',
    href: Route.featuredBuilds(),
  },
  ...tagCloudOptionsWithoutAll,
];
